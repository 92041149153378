<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-row>
            <v-col cols="12" class="text-center py-16">
              <v-img
                height="90"
                src="@/assets/images/shopware_logo.svg"
                contain
                style="margin-top: 10px; margin-bottom: 10px"
              ></v-img>
            </v-col>
          </v-row>

          <v-toolbar dark color="primary" flat>
            <v-toolbar-title>
              {{ $t("settings.lang_shopwareSettings") }}
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon>
              <v-icon>refresh</v-icon>
            </v-btn>
          </v-toolbar>

          <v-progress-linear
            class="ma-0"
            color="primary"
            indeterminate
            v-if="this.loading"
          ></v-progress-linear>

          <v-form ref="form" v-model="valid" class="pa-5">
            <v-row>
              <v-col cols="6">
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-text-field
                      :disabled="this.loading"
                      :label="$t('settings.lang_shopwareUrl')"
                      :rules="[rules.required, rules.https]"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      @focus="showTouchKeyboard"
                      autocomplete="off"
                      dense
                      outlined
                      required
                      v-model="shopwareUrl"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      :disabled="this.loading"
                      :label="$t('generic.lang_clientID')"
                      :rules="[rules.required]"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      @focus="showTouchKeyboard"
                      autocomplete="off"
                      dense
                      outlined
                      required
                      v-model="clientId"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="6">
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-text-field
                      :disabled="this.loading"
                      :label="$t('settings.lang_mailSettingsUsername')"
                      :rules="[rules.required]"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      @focus="showTouchKeyboard"
                      autocomplete="off"
                      dense
                      outlined
                      required
                      v-model="username"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      :disabled="this.loading"
                      :label="$t('settings.lang_mailSettingsPassword')"
                      :rules="[rules.required, rules.min]"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      @focus="showTouchKeyboard"
                      autocomplete="off"
                      dense
                      outlined
                      required
                      type="password"
                      v-model="password"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-divider class="mt-0" />

            <v-row justify="end">
              <v-btn
                color="success"
                :disabled="loading || !valid"
                :loading="loading"
                @click="saveSettings"
              >
                {{ $t("generic.lang_save") }}
              </v-btn>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard
        :accept="hideTouchKeyboard"
        :cancel="hideTouchKeyboard"
        :defaultKeySet="touchKeyboard.keySet"
        :input="touchKeyboard.input"
        :layout="touchKeyboard.layout"
        :options="touchKeyboard.options"
        class="internalWidth"
        id="onScreenKeyboard"
        v-if="touchKeyboard.visible"
      />
    </div>
  </v-container>
</template>

<script>
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import mixin from "@/mixins/KeyboardMixIns";

export default {
  name: "ShopwareComponent",

  mixins: [mixin],

  data() {
    return {
      loading: false,
      valid: false,
      shopwareUrl: "",
      clientId: "",
      username: "",
      password: "",
      rules: {
        required: (field) => {
          return (
            (field && field !== "" && field.toString().length > 0) ||
            this.$t("generic.lang_requiredField")
          );
        },
        min: (field) => {
          return (
            (field && field.toString().length >= 6) ||
            this.$t("generic.lang_passwordShouldBeAtLeast6CharsMinimum")
          );
        },
        https: (v = "") => {
          return (
            (v && v.indexOf("https://") === 0) || this.$t("generic.lang_httpsLinkRequired")
          );
        },
      },
    };
  },
  mounted() {
    this.getInfos();
  },
  methods: {
    getInfos() {
      this.loading = true;

      this.axios
        .post(ENDPOINTS.SETTINGS.MODULESETTINGS.SHOPWARE.GET)
        .then((res) => {
          if (res.data.status === "success") {
            this.shopwareUrl = res.data.shopwareUrl;
            this.clientId = res.data.clientId;
            this.username = res.data.username;
            this.password = res.data.password;
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveSettings() {
      this.loading = true;

      this.axios
        .post(ENDPOINTS.SETTINGS.MODULESETTINGS.SHOPWARE.UPDATE, {
          shopwareUrl: this.shopwareUrl,
          clientId: this.clientId,
          username: this.username,
          password: this.password,
        })
        .then((res) => {
          if (res.data.status === "SUCCESS") {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_actionSuccessful"),
              color: "success",
            });
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
